var intervalIdVerificarSessao = null;

var countdownValue = 1;
var valorOriginal = 0;
var sessaoExpira = 0;;
var sessaoExpiraText = 0;
var avisoSessaoExpira = null;
var avisoSessaoExpiraText = 0;
var strongElement = null;
var worker = null;
var percentExpiries = null;
var paraValidacao = false;
var bloqueado = false;

$(document).ready(function () {
     if($('#sessaoExpira').length){
        sessaoExpira = $('#sessaoExpira');
        sessaoExpiraText = $('.sessaoExpiraText');
        strongElement = sessaoExpira.find('strong');
        countdownValue = toInteger($('#sessaoExpira').text());
        valorOriginal = toInteger($('#sessaoExpira').text());
    }

    let usuarioLogado = verificaSessao();

    if (usuarioLogado) {
        startWorker();

        $(document).ajaxSuccess(function (event, xhr, settings) {
            resetCountdown();
        });

        $('a[target="_blank"]').click(function () {
            resetCountdown();
        });

        window.addEventListener('storage', function (event) {
            if (event.key === 'countdownValue') {
                countdownValue = parseInt(event.newValue, 10);
                strongElement.html(countdownValue);
                restartWorker();
            } else if (event.key === 'paraValidacao') {
                paraValidacao = event.newValue === 'true' ? true : false;
                if (paraValidacao) {
                    bloquear();
                } else {
                    if ($('#lockscreen').length) {
                        $('#lockscreen').hide();
                        $('#lockscreen').remove();
                    }
                }
            } else if (event.key === 'fecharModal' && event.newValue === 'true') {
                if ($('.overlay-lockscreen').length) {
                    $('.overlay-lockscreen').hide();
                    $('.overlay-lockscreen').remove();
                }
                localStorage.removeItem('fecharModal');
            }
        });
    }
});

$(function () {
    $.each($('a.disabled'), function () {
        $(this).click(function () {
            return false;
        });
    });

    $('#panel-abas li a').click(function () {
        PanelEsq($(this).attr('href'));
        return false;
    });
    PanelEsq('#ap1');
});

function PanelEsq(id) {
    $('#panel-content div.paneltabs').hide();
    $(id).show();
    $('#panel-abas li a').removeClass('active');
    $('a[href=' + id + ']').addClass('active');
}

//** Smooth Navigational Menu- By Dynamic Drive DHTML code library: http://www.dynamicdrive.com
//** Script Download/ instructions page: http://www.dynamicdrive.com/dynamicindex1/ddlevelsmenu/
var ddsmoothmenu = {
    arrowimages: {
        down: ['seta-menu', 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAICAYAAAAvOAWIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAE1JREFUeNqEjgEKACAIA7deVv9/lJUVaQkO0tA7kDICgMgj5XwycJZCsiWCzpVbV6DKDcyTfWbVdhaB4EAHB4IDP9gKLxjCRvjmXYABAFVcoTBSk1SvAAAAAElFTkSuQmCC'],
        right: ['seta-submenu', 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAARVJREFUeNqckz0KwkAQhdcfPIAgsRUWvIDYpBP0GilS5QLBQrASJLaCjTfwBrE1hbVlgoKtIAQEQRB8I7OyLolRBz5Isu+9TLKzpSiKRE61QIOvzyDJEpWN+wpwQQz2YMvEjMuazIA6CMESSNu2hZRS10peC1n7FlADK9BTC0EQCMuyzBDBmhV7XgGebqaiDgpCPBVA3zTM+kEFIeSpUEAXNPO24kMIeboU0BYF9SGkXTa3Ja9833+GmNtOAcdvO3AcRyTJ2zwdKWADLn+YU/JSwBXMfzRTLcir5mBqznqBOWHPa5ConQHY6ZOYY96xNjXPwgF0wATcqQPDfANj1hzUw6qRTqIRmIG+dpxPYK3eqtdDgAEAUYResR8o02sAAAAASUVORK5CYII=']
    },
    transition: { overtime: 300, outtime: 300 }, //duration of slide in/ out animation, in milliseconds
    shadow: { enable: true, offsetx: 2, offsety: 5 }, //enable shadow?
    showhidedelay: { showdelay: 275, hidedelay: 350 }, //set delay in milliseconds before sub menus appear and disappear, respectively
    // N�o editavel >>>
    detectwebkit: navigator.userAgent.toLowerCase().indexOf("applewebkit") != -1, //detect WebKit browsers (Safari, Chrome etc)
    detectie6: document.all && !window.XMLHttpRequest,
    css3support: window.msPerformance || (!document.all && document.querySelector), //detect browsers that support CSS3 box shadows (ie9+ or FF3.5+, Safari3+, Chrome etc)
    getajaxmenu: function ($, setting) { //function to fetch external page containing the panel DIVs
        var $menucontainer = $('#' + setting.contentsource[0]) //reference empty div on page that will hold menu
        $menucontainer.html("Carregando...")
        $.ajax({
            url: setting.contentsource[1], //path to external menu file
            async: true,
            error: function (ajaxrequest) {
                $menucontainer.html('Oopps! Aconteceu um imprevisto! Veja a resposta do servidor: ' + ajaxrequest.responseText)
            },
            success: function (content) {
                $menucontainer.html(content)
                ddsmoothmenu.buildmenu($, setting)
            }
        })
    },
    buildmenu: function ($, setting) {
        var smoothmenu = ddsmoothmenu
        var $mainmenu = $("#" + setting.mainmenuid + ">ul") //reference main menu UL
        if ($mainmenu.parent().get(0)) {
            $mainmenu.parent().get(0).className = setting.classname || "ddsmoothmenu"
            var $headers = $mainmenu.find("ul").parent()
            $headers.hover(
                function (e) {
                    $(this).children('a:eq(0)').addClass('selected')
                },
                function (e) {
                    $(this).children('a:eq(0)').removeClass('selected')
                }
            )

            $headers.each(function (i) { //loop through each LI header
                var $curobj = $(this).css({ zIndex: 100 - i }) //reference current LI header
                var $subul = $(this).find('ul:eq(0)').css({ display: 'block' })
                $subul.data('timers', {})
                this._dimensions = {
                    w: this.offsetWidth,
                    h: this.offsetHeight,
                    subulw: $subul.outerWidth(),
                    subulh: $subul.outerHeight()
                }
                this.istopheader = $curobj.parents("ul").length == 1 ? true : false //is top level header?
                $subul.css({ top: this.istopheader && setting.orientation != 'v' ? this._dimensions.h + "px" : 0 })
                $curobj.children("a:eq(0)").css(this.istopheader ? { paddingRight: smoothmenu.arrowimages.down[2] } : {}).append(//add arrow images
                    '<img src="' + (this.istopheader && setting.orientation != 'v' ? smoothmenu.arrowimages.down[1] : smoothmenu.arrowimages.right[1])
                    + '" class="' + (this.istopheader && setting.orientation != 'v' ? smoothmenu.arrowimages.down[0] : smoothmenu.arrowimages.right[0])
                    + '" style="border:0;" />'
                )
                if (smoothmenu.shadow.enable && !smoothmenu.css3support) { //if shadows enabled and browser doesn't support CSS3 box shadows
                    this._shadowoffset = {
                        x: (this.istopheader ? $subul.offset().left + smoothmenu.shadow.offsetx : this._dimensions.w),
                        y: (this.istopheader ? $subul.offset().top + smoothmenu.shadow.offsety : $curobj.position().top)
                    } //store this shadow's offsets
                    if (this.istopheader)
                        $parentshadow = $(document.body)
                    else {
                        var $parentLi = $curobj.parents("li:eq(0)")
                        $parentshadow = $parentLi.get(0).$shadow
                    }
                    this.$shadow = $('<div class="ddshadow' + (this.istopheader ? ' toplevelshadow' : '') + '"></div>').prependTo($parentshadow).css({
                        left: this._shadowoffset.x + 'px',
                        top: this._shadowoffset.y + 'px'
                    })  //insert shadow DIV and set it to parent node for the next shadow div
                }
                $curobj.hover(
                    function (e) {
                        var $targetul = $subul //reference UL to reveal
                        var header = $curobj.get(0) //reference header LI as DOM object
                        clearTimeout($targetul.data('timers').hidetimer)
                        $targetul.data('timers').showtimer = setTimeout(function () {
                            header._offsets = { left: $curobj.offset().left, top: $curobj.offset().top }
                            var menuleft = header.istopheader && setting.orientation != 'v' ? 0 : header._dimensions.w
                            menuleft = (header._offsets.left + menuleft + header._dimensions.subulw > $(window).width()) ? (header.istopheader && setting.orientation != 'v' ? -header._dimensions.subulw + header._dimensions.w : -header._dimensions.w) : menuleft //calculate this sub menu's offsets from its parent
                            if ($targetul.queue().length <= 1) { //if 1 or less queued animations
                                $targetul.css({
                                    left: menuleft + "px",
                                    width: header._dimensions.subulw + 'px'
                                }).animate({ height: 'show', opacity: 'show' }, ddsmoothmenu.transition.overtime)
                                if (smoothmenu.shadow.enable && !smoothmenu.css3support) {
                                    var shadowleft = header.istopheader ? $targetul.offset().left + ddsmoothmenu.shadow.offsetx : menuleft
                                    var shadowtop = header.istopheader ? $targetul.offset().top + smoothmenu.shadow.offsety : header._shadowoffset.y
                                    if (!header.istopheader && ddsmoothmenu.detectwebkit) { //in WebKit browsers, restore shadow's opacity to full
                                        header.$shadow.css({ opacity: 1 })
                                    }
                                    header.$shadow.css({
                                        overflow: '',
                                        width: header._dimensions.subulw + 'px',
                                        left: shadowleft + 'px',
                                        top: shadowtop + 'px'
                                    }).animate({ height: header._dimensions.subulh + 'px' }, ddsmoothmenu.transition.overtime)
                                }
                            }
                        }, ddsmoothmenu.showhidedelay.showdelay)
                    },
                    function (e) {
                        var $targetul = $subul
                        var header = $curobj.get(0)
                        clearTimeout($targetul.data('timers').showtimer)
                        $targetul.data('timers').hidetimer = setTimeout(function () {
                            $targetul.animate({ height: 'hide', opacity: 'hide' }, ddsmoothmenu.transition.outtime)
                            if (smoothmenu.shadow.enable && !smoothmenu.css3support) {
                                if (ddsmoothmenu.detectwebkit) { //in WebKit browsers, set first child shadow's opacity to 0, as "overflow:hidden" doesn't work in them
                                    header.$shadow.children('div:eq(0)').css({ opacity: 0 })
                                }
                                header.$shadow.css({ overflow: 'hidden' }).animate({ height: 0 }, ddsmoothmenu.transition.outtime)
                            }
                        }, ddsmoothmenu.showhidedelay.hidedelay)
                    }
                ) //end hover
            }) //end $headers.each()
            if (smoothmenu.shadow.enable && smoothmenu.css3support) { //if shadows enabled and browser supports CSS3 shadows
                var $toplevelul = $('#' + setting.mainmenuid + ' ul li ul')
                var css3shadow = parseInt(smoothmenu.shadow.offsetx) + "px " + parseInt(smoothmenu.shadow.offsety) + "px 5px #aaa" //construct CSS3 box-shadow value
                var shadowprop = ["boxShadow", "MozBoxShadow", "WebkitBoxShadow", "MsBoxShadow"] //possible vendor specific CSS3 shadow properties
                for (var i = 0; i < shadowprop.length; i++) {
                    $toplevelul.css(shadowprop[i], css3shadow)
                }
            }
            $mainmenu.find("ul").css({ display: 'none', visibility: 'visible' })
        }
    },
    init: function (setting) {
        if (typeof setting.customtheme == "object" && setting.customtheme.length == 2) { //override default menu colors (default/hover) with custom set?
            var mainmenuid = '#' + setting.mainmenuid
            var mainselector = (setting.orientation == "v") ? mainmenuid : mainmenuid + ', ' + mainmenuid
            document.write('<style type="text/css">\n'
                + mainselector + ' ul li a {background:' + setting.customtheme[0] + ';}\n'
                + mainmenuid + ' ul li a:hover {background:' + setting.customtheme[1] + ';}\n'
                + '</style>')
        }
        this.shadow.enable = (document.all && !window.XMLHttpRequest) ? false : this.shadow.enable //in IE6, always disable shadow
        jQuery(document).ready(function ($) { //ajax menu?
            if (typeof setting.contentsource == "object") { //if external ajax menu
                ddsmoothmenu.getajaxmenu($, setting)
            } else { //else if markup menu
                ddsmoothmenu.buildmenu($, setting)
            }
        })
    }
} //end
function breadcrumb_path() {
    var home = $("#breadcrumb ul li").first();
    var link_atual;
    var breadcrumb = $("#breadcrumb ul");
    breadcrumb.children("li").remove();
    breadcrumb.append(home);
    if (window.location.href.toString() != window.location.origin.toString() + '/') {
        $.each($("#menu-drop > ul > li a"), function (e) {
            if ($(this).attr('href') === window.location.href.replace(window.location.origin, '')) {
                link_atual = $(this);
                $(this).parents('li');
            }
        });
    }
    jQuery.each(link_atual.parents('li').get().reverse(), function () {
        var a = $(this).children('a').first();
        if (a.text() != link_atual.text()) {
            breadcrumb.append("<li> " + a.text() + " </li>");
            // breadcrumb.append("<li> <a href='" + a.attr('href')+ "'>" + a.text() +" </a></li>");
        }
    });
    breadcrumb.append("<li>" + link_atual.text() + "</li>");
}

function search_menu() {
    // Adiciona os items no select
    $("#menu-drop ul li").each(function () {
        if ($(this).children("ul").size() == 0) {
            $("#menu-busca-input").append('<option value="' + $(this).children().filter('a').first().attr('href') + '">' + $(this).children().filter('a').first().attr('title') + '</option>');
        }
    });
    // redireciona para o link do menu selecionado
    $("#menu-busca-input").select2({
        placeholder: "Busca no menu",
        minimumInputLength: 1,
        allowClear: true
    }).on("click", function () {
        let opcaoSelecionada = $(".select2-container > a > span").html();
        let deveAbrirNovaGuia = opcaoSelecionada != "Busca no menu";
        if (deveAbrirNovaGuia) {
            window.open($(this).select2("val"), '_blank');
        }
    });
}

function configureMaskQuantidade(fieldName, index, extras) {
    var fields = fieldName.split('|');
    var precision = parseInt(extras ? extras[index] : null);

    if (isNaN(precision)) {
        precision = 0;
    }

    $.each(fields, function (key, field) {
        if (precision == 0) {
            $('#' + field).maskMoney({ showSymbol: false, decimal: "", thousands: "", precision: 0, allowZero: true });
        } else {
            if (extras[index] !== undefined && parseInt(extras[index]) !== 0) {
                $('#' + field).maskMoney({ showSymbol: false, decimal: ",", thousands: ".", precision: precision, allowZero: true });
            } else {
                $('#' + field).maskMoney({ showSymbol: false, decimal: "", thousands: "", precision: 0, allowZero: true });
            }
        }
    });
}

// =============================================================================================
// Função reescrita para adicionar/remover a '*' de requerido.
// =============================================================================================
function addRemRequerid(id, requerido) {
    if (requerido) {
        $(id).parent().parent().find('label').addClass('required');
    } else {
        $(id).parent().parent().find('label').removeClass('required');
    }
}

function showBlockUiSession(message, timeOut = 25000) {
    toastr.options = {
        closeButton: true,
        progressBar: true,
        preventDuplicates: false,
        timeOut: timeOut
    };
    toastr.error(message, 'Sessão');
}

function bloquear() {
    telaBloqueada();
    $('#lockscreen-btn').click(function () {
        var senha = $('#lockscreen-password').val();
        if (senha) {
            $.ajax({
                url: '/conta/loginbloqueado',
                data: {
                    id_usuario: $('#id_usuer_logged').val(),
                    usuario: $('#lockscreen-usuario').val(),
                    ups: $('#ups_usuer_logged').val(),
                    papel: $('#papel_usuer_logged').val(),
                    senha: senha
                },
                type: 'POST',
                async: true,
                cache: false,
                contentType: "application/x-www-form-urlencoded",
                success: function (data) {
                    if (data.success) {
                        $('#lockscreen').hide();
                        $('#lockscreen').remove();
                        paraValidacao = false;
                        countdownValue = valorOriginal;
                        localStorage.setItem('countdownValue', countdownValue);
                        localStorage.setItem('paraValidacao', paraValidacao);
                    } else {
                        alert(data.message);
                        $('#lockscreen-password').val("");
                    }

                    if ($('.overlay-lockscreen').length) {
                        $('.overlay-lockscreen').hide();
                        $('.overlay-lockscreen').remove();
                    }

                    if (data.redirect) {
                        window.location.href = '/home/index';
                    }
                },
                error: function (xhr, desc, err) {
                    return false;
                }
            });
        }
    });
}

function telaBloqueada() {
    let usuarioNome = ($('#nome_usuer_logged').val()) ? $('#nome_usuer_logged').val() : "Usuário"

    $('body').append(`
    <div class="lockscreen" id="lockscreen">
        <div class="lock-nome">` + usuarioNome + `</div>
        <div class="lock-msg">Insira sua senha para desbloquear a tela.</div>
        <input class="lockscreen-password" id="lockscreen-usuario" type="text" title="Usuário" style="margin-bottom: 5px;" placeholder="Usuário"></input>
        <input class="lockscreen-password" id="lockscreen-password" type="password" title="Senha" placeholder="Senha"></input>
        <input type="submit" class="lockscreen-btn" id="lockscreen-btn" value="DESBLOQUEAR"></input>
    </div>
`);
    $('#lockscreen').fadeIn();
}

function callbackAviso(id) {
    $('#avisosnotificacao_linha_' + id.idAcesso).remove();
}

function atendeSolicitacao(id) {
    if (confirm("Deseja realmente inativar o integrante?")) {
        $('#avisosnotificacao_' + id + '-acao').append("<img id='loader' src='/img/ajax-loader-flower.gif' />");
        $.ajax({
            url: '/home/atendersolicitacao/id/' + id,
            type: 'POST',
            async: true,
            cache: false,
            contentType: "application/x-www-form-urlencoded",
            complete: function () {
                $('#avisosnotificacao_' + id + '-acao #loader').remove();
            },
            success: function (data) {
                if (data['success']) {
                    window.location.reload(); //melhorar isso para atualizar apenas o grid e não a página
                } else {
                    $('#erros_avisos').text(data['msg']);
                    $('#erros_avisos').show();
                }
            },
            error: function (xhr, desc, err) {
                console.error(xhr);
            }
        });
    }
}

function negaSolicitacao(id) {
    $('#avisosnotificacao_' + id + '-acao').append("<img id='loader' src='/img/ajax-loader-flower.gif' />");
    $.ajax({
        url: '/home/negarsolicitacao/id/' + id,
        type: 'GET',
        async: true,
        cache: false,
        contentType: "application/x-www-form-urlencoded",
        success: function (data) {
            $.blockUI({
                theme: true,
                draggable: true,
                bindEvents: false,
                centerY: true,
                title: 'Observação <a href=\"javascript:;\" class=\"btn-i-nao right fechar_msg\"></a>',
                message: data,
                themedCSS: {
                    top: '30%'
                }
            });
            $(document).on('click', '.fechar_msg', function () {
                $.unblockUI();
            });
        },
        error: function (xhr, desc, err) {
            console.error(xhr);
        }
    });
}

function restartWorker() {
    if (worker) {
        worker.terminate();
    }
    startWorker();
}

function resetCountdown() {
    countdownValue = valorOriginal;
    localStorage.setItem('countdownValue', countdownValue);
    restartWorker();
}

function exibeAvisaExpiraSessao() {
    $('body').append(`
        <div class="overlay-lockscreen">
            <div class="div-lockscreen">
                <h4 class="msg-lockscreen aviso-sessao-expira">Sua sessão irá expirar em <strong>${alerta_expira_sessao}</strong> segundos. O que você deseja fazer?</h4>
                <div class="button-lockscreen">
                    <button onclick="renovaSessao()">Renovar sessão</button>
                    <button class="btn_remover"><a href="/conta/logout">Encerrar sessão</a></button>
                </div>
            </div>
        </div>
    `);
}

function startWorker() {
    if (window.Worker) {
        const workerCode = `
        let countdownValue;

        onmessage = function(event) {
            const message = event.data;

            if (message.action === 'start') {
                countdownValue = message.value;
                updateCountdown();
            } else if (message.action === 'reset') {
                countdownValue = message.value;
            }
                
            function updateCountdown() {
                if (countdownValue < 1) {
                    postMessage({ action: 'expired' });
                } else {
                    postMessage({ action: 'update', value: countdownValue });
                    countdownValue--;
                    setTimeout(updateCountdown, 1000);
                }
            }
        };
        `;

        if ($('#lockscreen') && localStorage.getItem('paraValidacao') === 'false') {
            $('#lockscreen').hide();
            $('#lockscreen').remove();
        }

        const blob = new Blob([workerCode], { type: 'application/javascript' });
        worker = new Worker(URL.createObjectURL(blob));
        worker.postMessage(countdownValue);

        worker.onmessage = function (event) {
            const message = event.data;

            if (message.action === 'update') {
                countdownValue = message.value;
                strongElement.html(countdownValue);
                localStorage.setItem('countdownValue', countdownValue);

                if (alerta_expira_sessao !== undefined && countdownValue <= toInteger(alerta_expira_sessao)) {
                    if(!$('.overlay-lockscreen').length){
                        exibeAvisaExpiraSessao();
                        avisoSessaoExpira = $('.aviso-sessao-expira');
                        avisoSessaoExpiraText = avisoSessaoExpira.find('strong');
                    }

                    avisoSessaoExpiraText.html(countdownValue);
                } else if ($('.overlay-lockscreen').length && countdownValue > toInteger(alerta_expira_sessao)) {
                    $('.overlay-lockscreen').hide();
                    $('.overlay-lockscreen').remove();
                }

                if (countdownValue < 11) {
                    // Verifica se o elemento strong existe antes de tentar manipular seus estilos
                    if (strongElement.length > 0) {
                        strongElement[0].style.color = 'red';
                    }
                } else {
                    if (strongElement.length > 0) {
                        strongElement[0].style.color = 'black';
                    }
                }
            } else if (message.action === 'expired') {
                if (usa_bloqueio_sessao != undefined && usa_bloqueio_sessao) {
                    verificaSessao();
                } else {
                    sessaoExpiraText.text('Sessão expirada, necessário login!');
                    if (sessaoExpiraText.length > 0) {
                        sessaoExpiraText[0].style.fontWeight = 'bold';
                    }
                    window.location.href = '/conta/logout';
                }
            }
        }
        if(!paraValidacao) {
            worker.postMessage({ action: 'start', value: countdownValue });
        }
    } else {
        console.error('O navegador utilizado não suporta a função de tempo de bloqueio!');
    }
}

function verificaSessao() {
    if (!$('#id_usuer_logged').val()) {
        return false;
    }
    if (!paraValidacao) {
        $.get('/conta/verificasessaoativa/id_usuario_sys/' + $('#id_usuer_logged').val()).success(function (data) {
            let dataExpiries = new Date(data.expiries * 1000);
            if (data.expiries !== null && data.register_logout === false) {
                countdownValue = toInteger((dataExpiries - new Date()) / 1000);
                strongElement.html(countdownValue);
                localStorage.setItem('countdownValue', countdownValue);
            }

            if (new Date() >= dataExpiries) {
                if (data.register_logout === true) {
                    bloquear();
                    paraValidacao = true;
                    localStorage.setItem('paraValidacao', paraValidacao);
                }
            }
        });
    }

    return true;
}

function renovaSessao() {
    $.ajax({
        url: '/home/index',
        type: 'GET',
        async: true,
        cache: false,
        contentType: "application/x-www-form-urlencoded",
        success: function (data) {
            $('.overlay-lockscreen').hide();
            $('.overlay-lockscreen').remove();
            countdownValue = valorOriginal;
            localStorage.setItem('countdownValue', countdownValue);
            localStorage.setItem('fecharModal', 'true');
            console.log('Sessão renovada');
        },
        error: function (xhr, desc, err) {
            console.error(xhr);
        }
    });
}
